import * as React from "react";
import PageComponent from "src/components/pages/get-a-quote";

const Page = ({ location }) => {
  const copy = {
    determineHealthClass: "Determine your health class",
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Get a Quote"
    />
  );
};

export default Page;
